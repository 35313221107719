import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GhostOverlayModel } from '../../ghost-overlay/model/ghost-overlay.model';
import { GhostOverlayModule } from '../../ghost-overlay/ghost-overlay.module';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIcon } from '@angular/material/icon';
import { ComponentState, GhostStateFragmentDirective } from '@futura/futura-ui/ghost';
import { InfoPanelComponent } from '@futura/futura-ui/info-panel';

@Component({
  selector: 'app-general-section',
  templateUrl: './general-section.component.html',
  styleUrls: ['./general-section.component.scss'],
  standalone: true,
  imports: [GhostOverlayModule, NgClass, TranslocoModule, MatIcon, NgTemplateOutlet, InfoPanelComponent, GhostStateFragmentDirective],
})
export class GeneralSectionComponent {
  @Input() public sectionTitle?: string;
  @Input() public argsTitle?: Record<string, unknown>;
  @Input() public isCard?: boolean;
  @Input() public type: 'light' | 'dark' = 'light';

  @Input() public hideIcon?: boolean;
  @Input() public showInfoIcon?: boolean;
  @Output() public readonly infoClick = new EventEmitter<void>();

  @Input() public sectionTitleRight?: TemplateRef<unknown>;
  @Input() public infoTitle?: string;
  @Input() public infoTemplate?: TemplateRef<unknown>;

  @Input() public state?: ComponentState;
  @Input() public ghostConfig: Partial<GhostOverlayModel> = {};
  @Output() public readonly ghostClick = new EventEmitter<void>();

  @Input() public userLabels?: Array<string>;
  @Input() public labels?: { lables: Array<string>; whitelist: boolean };
  @Input() public locked?: boolean;
  @Input() public lockConfig: Partial<GhostOverlayModel> = {
    icon: 'block',
    title: 'lock.text.title',
    actionButton: 'lock.text.action',
  };
  @Output() public readonly lockClick = new EventEmitter<void>();
}
